.support_main_container {
  padding: 0 2rem;
}

.support_container {
  background-color: #ffffff;
  padding: 2.44rem 2rem;
}

.search_container {
  width: 100%;
  display: flex;
  border: 1px solid #d8d8d8;
  margin: 0 auto;
  border-radius: 0.3125rem;
}

.search_input {
  width: 100%;
  padding: 0.5rem;
  outline: none;
}
.search_input::placeholder {
  font-size: 16px;
}

.search_icon_div {
  background-color: #00ba77;
  padding: 0.5rem;
}

.tags {
  display: flex;
  align-items: center;
  gap: 0.62rem;
  margin: 2rem 0;
}

.tags p {
  /* Your styling rules for the first two <p> tags go here */
  color: #00ba77;
}
.tags p:nth-child(5) {
  /* Your styling rules for the first two <p> tags go here */
  color: #161616;
}

.single_support_grid_main_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 2rem;
  gap: 2rem;
}

.big_grid {
  border: 1px solid #d8d8d8;
  padding: 1.5rem;
}

.head_p {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 2rem;
  color: #16161699;
}

.single_support_grid_questions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.navigation_container {
  padding: 0 2rem;
}

.navigation_container h1 {
  padding-bottom: 2rem;
  border-bottom: 1px solid #d8d8d8;
}

.support_navs {
  margin-top: 2rem;
  padding-left: 1.5rem;
}
.support_navs p {
  color: #161616;
}

.single_support_grid_heading {
  font-weight: 700;
  color: #161616;
}

.ticket_main_container {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  background-color: rgba(0, 186, 119, 0.1);
  border: 1px solid #00ba77;
  border-radius: 0.3125rem;
  align-items: center;
}

.ticket_main_container h1 {
  font-size: 1.5rem;
  color: #161616;
  font-weight: 700;
}

.ticket_main_container p {
  font-size: 1;
  color: #16161699;
  font-weight: 400;
}

.ticket_button {
  background: #00ba77;
  border: none;
  border-radius: 0.3125rem;
  padding: 0.75rem 1.5rem;
  color: white;
  font-weight: 500;
}

@media (max-width: 768px) {
  .single_support_grid_main_container {
    grid-template-columns: 1fr;
  }
  .ticket_main_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem;
    background-color: rgba(0, 186, 119, 0.1);
    border: 1px solid #00ba77;
    border-radius: 0.3125rem;
    align-items: center;
  }
  .navigation_container {
    display: none;
  }
  .big_grid {
    border: none;
    padding: 0px;
  }
  .tags {
    margin: 1rem 0rem;
  }
  .single_support_grid_heading {
    margin-bottom: 1rem;
  }
}
