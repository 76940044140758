.privacySecurityCard {
  display: flex;
  flex-direction: column;
  padding: 40px 300px 100px;
  height: calc(100dvh - 60px - 54px);

}
.twoFactorAuthentication h3 {
  font-weight: 500;
  font-size: 14px;
  color: black;
}
.twoFactorAuthentication p {
  font-weight: 400;
  font-size: 14px;
  color: #939497;
  width: 411px;
}
.privacySecurityMidDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
}
.textMessageDiv h3 {
  font-weight: 500;
  font-size: 14px;
  color: black;
}
.textMessageDiv p {
  font-weight: 400;
  font-size: 14px;
  color: #939497;
  width: 411px;
}
.greenDiv {
  background-color: #00ba77;
  width: 50px;
  height: 22px;
  border-radius: 3px;
  margin-top: 15px;
  cursor: pointer;
}
.greyDiv {
  background-color: #dfdfdf;
  width: 50px;
  height: 22px;
  border-radius: 3px;
  margin-top: 15px;
  cursor: pointer;
}
.whiteDiv {
  background-color: #ffffff;
  width: 22px;
  height: 18px;
  margin: 2px;
  border-radius: 3px;
  margin-left: 26px;
}
.greyWhiteDiv {
  background-color: #ffffff;
  width: 22px;
  height: 18px;
  margin: 2px;
  border-radius: 3px;
}
.changePasswordDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
}
.changePasswordDiv h3 {
  font-weight: 500;
  font-size: 20px;
  margin-top: 5px;
}
.changePasswordDiv a {
  font-size: 13px;
  font-weight: 400;
  color: #00ba77;
  text-decoration: underline;
  margin-top: 20px;
  cursor: pointer;
}
.private_token_container {
  display: flex;
  gap: 1rem;
  margin-top: 10px;
}
.private_token_container input {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

@media (max-width: 800px) {
  .privacySecurityCard {
    display: flex;
    flex-direction: column;
    padding: 40px 70px 200px;
  }
}
@media (max-width: 426px) {
  .privacySecurityCard {
    display: flex;
    flex-direction: column;
    padding: 16px 10px 20px;
    height: calc( 100dvh - 60px - 40px );
  }
  .twoFactorAuthentication p {
    width: 256px;
    font-size: 13px;
  }
  .twoFactorAuthentication h3 {
    font-size: 13px;
  }
  .textMessageDiv h3 {
    font-size: 13px;
  }
  .textMessageDiv p {
    width: 256px;
    font-size: 13px;
  }
  .changePasswordDiv {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .changePasswordDiv h3 {
    margin-bottom: 0;
    font-size: 14px;
  }
  .changePasswordDiv a {
    font-size: 13px;
  }
  .greenDiv{
    width: 34px;
  }
  .whiteDiv {
    width: 15px;
    margin-left: 16px;
  }
  .greyDiv {
   width: 34px;
  }
  .greyWhiteDiv {
    width: 16px;
  }
}
@media (max-width: 330px) {
.greyWhiteDiv{
  margin-right: 15px;
}
}