
.rounded-cards {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  /* padding: 10px; */
  margin: 10px;
  background-color: #fff;
  /* margin: 10px;
  background-color: #fff; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.home-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  border-bottom: 1px solid #ccc;
}

.home-card-header h2 {
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  padding: 20px 0px;
}

.home-card-header p {
  margin: 0;
  font-size: 14px;
  color: #777;
}

.card-body {
  padding: 10px 50px;
}

.cards-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.equity-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  /* width: 96%; */
  /* margin: 10px; */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  align-self: stretch;
  position: relative;
}

.equity-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.equity-card-header .heading {
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}

.opening-balance {
  color: #989898;
  font-size: 12px;

}

.balance {
  color: #00ba77;
}
.homeNoText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
}

.profit-loss-container {
  width: 35%;
  /* display: flex; */
  /* justify-content: center; */
  margin: 15px 0px;
}

.equity-card-body {
  flex: 1;
}

.equity-card-body p {
  color: #00ba77;
  font-size: 60px;
  text-align: center;
  height: 100%;
  width: 100%;
}
.homeNoTextEquity {
  display: flex;
  padding-top: 50px;
  justify-content: center;
}

.wallet-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 25px;
  padding: 5px 10px 5px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  /* width: 96%; */
  /* margin: 10px; */
  /* background-color: #00ba77; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.wallet-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.wallet-card-header .heading {
  color: #f9f9f9;
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}

.wallet-balance {
  color: #f9f9f9;
  font-size: 12px;
}

.wallet-balance .balance {
  color: white;
  font-weight: 500;
}

.wallet-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.wallet-item img {
  width: 172px;
  height: 150px;
  padding: 10px 0px;
}
.wallet-row img {
  width: 150px;
  height: 120px;
}

.right-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-item p {
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.right-item .info {
  padding: 10px 0px;
  font-size: 12px;
  font-weight: 300;
  /* width: 235px; */
  padding-bottom: 18px;
}

.right-item button {
  width: 105px;
  height: 30px;
  font-size: 12px;
  font-weight: 300;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.holdings-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  /* width: 96%; */
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  /* height: 273px; */
}

.holdings-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.holdings-card-headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.holdings-left-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.additional-heading {
  font-size: 12px;
  font-weight: 400;
  color: #989898;
  margin: 2px 0px;
}

.holdings-card-header .heading {
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}

.balance {
  color: #00ba77;
}

.holdings-card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 10px;
}

.holdings-card-body img {
  /* width: 300px; */
  height: 120px;
}

.holdings-card-body p {
  align-items: center;
  justify-content: center;
  color: #00ba77;
  display: flex;
  font-size: 60px;
  text-align: center;
}

.selectorDiv {
  height: 35px;
  width: 135px;
  border: 0.5px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.selector {
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  padding: 0px;
  border: none;
  outline: none;
}

.portfolioMarket {
  display: flex;
  flex-direction: row;
}

.portfolioMarket .item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
}

.item p {
  margin-left: 5px;
}
.yellow {
  width: 13px;
  height: 13px;
  border-radius: 10px;
  background: #ffa500;
}

.green {
  width: 13px;
  height: 13px;
  border-radius: 10px;
  background: #00ba77;
}

.market-card-body {
  /* height: 250px; */
  width: 100%;
}

.inviteContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0px;
}
.inviteWrapper {
  border: 1px solid #d9d9d9;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  border-radius: 5px;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.inviteBtn {
  border: none;
  background: #00ba77;
  color: white;
  padding: 8px 0px;
  width: 120px;
  cursor: pointer;
}

@media (max-width: 901px) {
  .home-card-header {
    padding: 10px 20px;
  }
  .card-body {
    padding: 10px 20px;
  }
  .cards-container {
        flex-direction: column;
  }    
  .wallet-container {
    width: 100%;
  }
  .wallet-row {
    gap: 20px;
  }
  .wallet-row img {
    width: 120px;
    height: 120px;
    padding: 10px 0px;
  }
  .right-item .info {
    padding-top: 0;
    margin-top: 5px;
    /* width: 150px; */
  }
  .right-item p {
    margin-bottom: 0;
  }
  .homeNoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
  }
  .profit-loss-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 15px 0px;
  }
  .holdings-card-headers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
}
@media (max-width: 450px) {
  .home-card-header {
    padding: 10px 20px;
  }
  .home-card-header h2 {
    font-size: 18px;
    font-weight: 500;
  }
  .card-body {
    padding: 10px;
  }
  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .holdings-left-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .holdings-container {
    height: auto;
  }
  .holdings-card-body {
    display: flex;
    flex-direction: column;
    padding-right: 50px;
  }
  .holdings-card-body img {
    display: flex;
    align-items: center;
    padding: 30px 0px;
  }
  .homeNoText {
    padding-top: 50px;
  }
  .holdings-card-header .heading {
    font-size: 18px;
    font-weight: 500;
  }
  .inviteContainer {
    grid-template-columns: 1fr;
  }
  .inviteWrapper img {
    width: 120px;
  }
  .inviteBtn {
    border: none;
    background: #00ba77;
    color: white;
    padding: 5px 0px;
    width: 100px;
    font-size: 14px;
  }

}
@media (max-width: 350px) {
  .rounded-card {
    margin: 3px 5px 0;
  }
  .home-card-header {
    padding: 10px 20px;
  }
  .home-card-header h2 {
    font-size: 18px;
    font-weight: 500;
    padding: 12px 0;
  }
  .equity-container {
    /* width: 87%; */
    height: 197px;
  }

  .homeNoText {
    padding-top: 25px;
  }
  .wallet-row img {
    width: 100px;
    height: 80px;
    padding: 10px 0px;
  }
}

@media (min-width: 1440px) {
  .rounded-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .rounded-card {
    max-width: 1200px;
  }
}
