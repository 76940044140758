.helpSupportCard {
  padding: 70px 150px 200px;
  height: calc(100dvh - 60px - 54px);

}
.helpSupport {
  padding-bottom: 15px;
}
.helpSupportLeft h3 {
  font-weight: 500;
  font-size: 14px;
}
.helpSupportLeft p {
  font-weight: 400;
  font-size: 14px;
  color: #676e7e;
}
.speakToUs a{
  font-weight: 400;
  font-size: 14px;
  color: #00ba77;
  margin-top: 0;
  cursor: pointer;
}
@media (max-width:780px) {
    .helpSupportCard{
        padding: 70px 70px 200px;
    }
}
@media (max-width:426px) {
    .helpSupportCard{
        padding: 15px 13px 200px;
        height: calc(100dvh - 60px - 40px);
    }
    .helpSupportLeft{
        width: 100%;
    }
    .helpSupportLeft p{
        font-weight: 400;
        font-size: 13px;
    }
    .speakToUs a{
        font-size: 12px;
        font-weight: 400;
        width: max-content;
    }
}

