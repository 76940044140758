.auth_main_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.auth_container {
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.auth_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.auth_form_div {
  display: flex;
  flex-direction: column;
}

.auth_form_div input {
  padding: 0.5rem;
}

.auth_form_div label {
  margin-bottom: 0.2rem;
}

.register_token_main_container {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.register_token_main_container h1 {
  text-align: center;
}

.register_token_main_container button {
  width: 100%;
  border: none;
  background-color: limegreen;
  color: white;
  padding: 0.5rem 0;
}

.register_token_container {
  display: flex;
  gap: 1rem;
}

.register_token_container input {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

@media (max-width: 426px) {
    .register_token_main_container {
        width: 100%;
      }

      .register_token_container input {
        width: 30px;
        height: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
      }

      .register_token_container {
        gap: 0.5rem;
      }
}
