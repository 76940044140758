.addBankDetailCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 0 200px;
}
.addBankDetail {
  padding: 20px 25px;
  border: 0.3px solid #cccccc;
  border-radius: 5px;
}
.bankDetailHeader {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.bankDetailHeader h3 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
}
.bankDetailHeader p {
  font-weight: 400;
  font-size: 14px;
  color: #515151;
}
.chooseBank p {
  font-weight: 400;
  font-size: 14px;
}
.chooseBank div {
  border: 0.5px solid #80808080;
  padding: 0 10px;
  border-radius: 5px;
}
.chooseBank select {
  height: 50px;
  width: 500px;
  border: none;
  outline: none;
  border-radius: 5px;
}
/* .chooseBank select option{
    font-weight: 400;
    font-size: 14px;
    color: #AAAAAA;
} */
.chooseAccountNo p {
  font-weight: 400;
  font-size: 14px;
  padding-top: 10px;
}
.chooseAccountNo input {
  height: 50px;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  width: -webkit-fill-available;
  padding: 0px 10px;
}
.buttonToSave {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.buttonToSave p {
  padding: 15px 234px;
  color: white;
  background-color: #02915d;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
@media (max-width: 426px) {
  .addBankDetailCard {
    padding: 40px 15px 200px;
  }
  .addBankDetail {
    padding: 5px 15px 15px;
  }
  .chooseBank select {
    width: 100%;
  }
  .buttonToSave {
    margin-top: 20px;
  }
  .buttonToSave p {
    padding: 15px 120px;
  }
}
@media (max-width: 340px) {
  .buttonToSave p {
    padding: 15px 100px;
  }
}
