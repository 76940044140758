.support_main_container {
  padding: 0 2rem;
}

.support_container {
  background-color: #ffffff;
  padding: 2.44rem 2rem;
}
.support-card {
  padding: 20px 30px 30px;
}
.search_container_main {
  width: 100%;
  display: flex;
  border: 1px solid #d8d8d8;
  margin: 0 auto;
  border-radius: 0.3125rem;
}

.search_input {
  width: 80%;
  padding: 0.5rem;
  outline: none;
}
.search_input::placeholder {
  font-size: 16px;
}

.search_icon_div {
  background-color: #00ba77;
  padding: 0.5rem;
}

.support_grid_main_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

/* .support_grid_container {
  padding: 1.5rem;
} */

.support_grid_heading {
  font-weight: 700;
  max-width: 320px;
  color: #161616;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #d8d8d8;
}

.support_grid_questions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.support_grid_questions p {
  max-width: 320px;
}

.see_more_container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.see_more_text {
  color: #00ba77;
  font-weight: 500;
}

.ticket_main_container {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  background-color: rgba(0, 186, 119, 0.1);
  border: 1px solid #00ba77;
  border-radius: 0.3125rem;
  align-items: center;
  margin-top: 40px;
}

.ticket_main_container h1 {
  font-size: 1.5rem;
  color: #161616;
  font-weight: 700;
}

.ticket_main_container p {
  font-size: 1;
  color: #16161699;
  font-weight: 400;
}

.ticket_button {
  background: #00ba77;
  border: none;
  border-radius: 0.3125rem;
  padding: 0.75rem 1.5rem;
  color: white;
  font-weight: 500;
}

@media (max-width: 768px) {
  .support_grid_main_container {
    grid-template-columns: 1fr;
  }
}
