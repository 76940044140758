.durationSelectionCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0 200px;
}
.durationSelectionText p {
  text-align: center;
  width: 366px;
  font-weight: 500;
  font-size: 25px;
}
.durationCards {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.durationCards a{
  text-decoration: none;
}
.durationCard {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
  border: 0.5px solid #cccccce5;
  border-radius: 5px;
  padding: 13px 30px;
  align-items: center;
}
.durationCard a {
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  color: black;
}
.durationCard p {
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
}
.percentage p {
  color: #00ba77;
  font-weight: 400;
  font-size: 14px;
}
@media (max-width: 600px) {
  .durationSelectionCard {
    padding: 30px 0 200px;
  }
  .durationSelectionText p {
    font-size: 18px;
    font-weight: 500;
    width: 263px;
  }
  .durationCard {
    width: 300px;
  }
  .durationCard a {
    font-weight: 500;
    font-size: 18px;
  }
  .durationCard p {
    font-weight: 500;
    font-size: 18px;
  }
}
@media (max-width: 426px) {
  .durationCard {
    width: 250px;
    padding: 0px 15px;
  }
}
@media (max-width: 340px) {
  .durationCard {
    width: 225px;
    padding: 0px 15px;
  }
}
