body {
        margin: 0;
        font-family: 'Space Grotesk';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        
}
/* html,body, * {
        scroll-behavior: smooth;
} */

code {
  font-family: 'Space Grotesk';
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4b5563;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1f2937;
}

.react-responsive-modal-modal {
        position: absolute;
        max-width: 800px;
        width: 95%;
}


.modalOverlay{
        width: 100vw;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        right: 0;
        top: 0;
        z-index: 120    !important;
        transition: all .3s ease-in-out;

        display: flex;
        justify-content: center;
        align-items: center;
}


.modalBox{
        z-index: 130    !important;
        background-color: #fff;
        border: 1px solid #E6E5E6;
        padding: 32px 40px;
        border-radius: 8px;
        
        width: 60%;
        

        @media screen and (max-width:950px) {
                max-width: none;
                width: 85%;
        }

        @media screen and (max-width:800px) {
                max-width: none;
                width: 95%;
        }
        
        @media screen and (max-width:675px) {
                padding: 16px;
        }




        >nav{
                display: flex !important; 
                justify-content: space-between;
                align-items: center;
                gap: 15px;
                
                margin-bottom: 24px;

                h5{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;     
                }
        }
       

        >section{
                min-height: 8rem;
                max-height: 80dvh;
                overflow-y: scroll;
        }


}

.circle_cancle{
        display: flex;
        justify-content: center;
        align-items: center;
        
        width:  32px;
        height:  32px;
        min-width:  32px;
        min-height:  32px;
        border-radius: 50%;
        border: 0.5px solid #D2CFD1;
        gap: 8px;
        cursor: pointer;
        transition: all .25s ease-in-out;

        svg{
                height: 16px;
                width: 16px;
        }

        .svgleft{
                height: 20px;
                width: 20px;
        }

        a{
                display: block;
        }

        &:hover, &:focus{
                background-color: #fdf3f8;

                transform: rotate(90deg);

                .svgleft{
                        transform: translate(-5px);
                }

        }

}

@media (max-width: 600px) {
        .react-responsive-modal-modal {
                margin: 0 !important;
        
        }
}
