.withdrawContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
}
.withdrawHeading {
  font-weight: 500;
  font-size: 25px;
  color: #151515;
  margin-bottom: 0;
}
.withdrawHeading h4 {
  margin-bottom: 0;
}
.portfolioBalance {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.portfolioBalance p {
  font-weight: 400;
  font-size: 13px;
}
.portfolioAmount p {
  color: #00ba77;
}
.withdrawText p {
  font-size: 14px;
  font-weight: 400;
  color: #484848;
}
.bankNameCard {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 444px;
  padding-top: 10px;
}
.bankNameCard p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  color: #151515;
}
.branchName p {
  color: #484848;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 15px 15px;
}

.branchName input,
.accountNumber input {
  /* width: 422px; */
  width: 94%;
  color: #484848;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 10px;
  outline: none;
  margin-top: 10px;
}
.accountNumberCard {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 444px;
  padding-top: 10px;
}
.accountNumberCard p {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  color: #151515;
  margin-top: 0px;
}
.accountNumber p {
  color: #484848;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 15px 15px;
}
.koredeText {
  margin-left: 348px;
  font-size: 12px;
  font-weight: 400;
  color: #00ba77;
}
.enterAmountCard {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 444px;
  padding-top: 30px;
  margin-bottom: 16px;
}
.enterAmountCard p {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #151515;
}
.enterAmount {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  column-gap: 10px;
}
.enterAmountLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 10px;
}
.enterAmountLeft p {
  margin-top: 0;
}
.enterAmountRight {
  margin-top: 0;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 10px;
  width: 75%;
}
.withdrawButton {
  border: 0.5px solid #80808080;
  padding: 14px 185px;
  background: #00ba77;
  border-radius: 5px;
}
.withdrawButton a {
  color: white;
  text-decoration: none;
}
.changeDetail p {
  color: #00ba77;

  margin-top: 20px;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 500px) {
  .withdrawContainer {
    padding: 40px 0 60px;
  }
  /* .withdrawText {
    display: none;
  } */
  .bankNameCard {
    width: 87%;
  }
  .accountNumberCard {
    width: 87%;
  }
  .enterAmountCard {
    width: 87%;
  }
  .enterAmountRight {
    width: 80%;
  }
  .koredeText {
    margin-left: 200px;
  }
  .withdrawButton {
    padding: 15px 120px;
  }
}
@media (max-width: 426px) {
  /* .withdrawText {
    display: none;
  } */
  .bankNameCard {
    width: 87%;
  }
  .accountNumberCard {
    width: 87%;
  }
  .enterAmountCard {
    width: 87%;
  }
  .enterAmountRight {
    width: 80%;
  }
  .koredeText {
    margin-left: 178px;
  }
  .withdrawButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0px;
    width: 87%;
  }
}
