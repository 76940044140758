.alertCard {
  display: flex;
  flex-direction: row;
}
.alertLeft {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: calc(100vh - 60px);
}
.alertRight {
  width: 75%;
  display: flex;
  height: calc(100vh - 60px);
}
.alertRightCard {
  flex: 1;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 2px;
  height: 100%;
}
.historyCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  margin: 2px;
  padding: 5px 20px;
  align-items: center;
}
.historyCard p {
  font-weight: 500;
  font-size: 22px;
  color: #5e5e5e;
}
.newChatButton p {
  font-weight: 500;
  font-size: 14px;
  color: white;
  background: #00ba77;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.alertLeftTop {
  /* overflow-y: auto;
  height: 70%; */
}

.alertLeftTop.scrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertLeftTop.scrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

.alertLeftTop.scrolling::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftTop.scrolling::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftTop.notscrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertLeftTop.notscrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
  background-color: transparent; /* Change the color as needed */
}

.alertLeftTop.notscrolling::-webkit-scrollbar-thumb {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftTop.notscrolling::-webkit-scrollbar-track {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.timeRemaining {
  background-color: white;
  border-radius: 5px;
  margin: 5px 2px;
}
.timeRemaining .date {
  font-size: 16px;
  font-weight: 500;
  color: #cccccccc;
  margin: 5px 0px;
}
.timeRemaining {
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
}

.timeRemaining .item {
  padding: 5px 0px;
  cursor: pointer;
}
.alertLeftBottom {
  background-color: white;
  border-radius: 5px;
  overflow-y: auto;
  height: 84%;
  padding: 10px;
  margin: 5px 2px;
}
.alertLeftBottom.scrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertLeftBottom.scrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

.alertLeftBottom.scrolling::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftBottom.scrolling::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftBottom.notscrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertLeftBottom.notscrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
  background-color: transparent; /* Change the color as needed */
}

.alertLeftBottom.notscrolling::-webkit-scrollbar-thumb {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftBottom.notscrolling::-webkit-scrollbar-track {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}
.alertLeftBottom h4 {
  font-weight: 500;
  font-size: 16px;
  color: #515151;
  margin: 10px 0px;
}
.alertRightText {
  display: flex;
  justify-content: center;
}
.alertRightHeader h4 {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 0;
  text-align: center;
}
.alertRightHeader p {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}
.alertRightTextCard {
  width: 100%;
  overflow-y: auto;
}

.alertRightTextCard.scrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertRightTextCard.scrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

.alertRightTextCard.scrolling::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertRightTextCard.scrolling::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertRightTextCard.notscrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertRightTextCard.notscrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
  background-color: transparent; /* Change the color as needed */
}

.alertRightTextCard.notscrolling::-webkit-scrollbar-thumb {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertRightTextCard.notscrolling::-webkit-scrollbar-track {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertRightText p {
  font-weight: 400;
  font-size: 13px;
  padding: 15px 25px;
  max-width: 400px;
  background: #f0f0f0;
  border-radius: 5px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th {
  background-color: #f5f5f5;
  color: black;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
}

.custom-table td,
.custom-table th {
  border: 0.5px solid #f0f0f0;
  padding: 8px;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
}

.custom-table img {
  padding: 5px;
}
@media (min-width: 900px) {
  .mobileAiPrompt {
    display: none;
  }
  .mobileAlertTop {
    display: none;
  }
}

@media (max-width: 768px) {
  .alertLeft {
    width: 35%;
  }

  .alertRight {
    width: 65%;
  }

  .alertRightText p {
    max-width: 300px;
  }
}
@media (max-width: 426px) {
  .alertCard {
    display: none;
  }
  .mobileAiPromptContainer {
    background: red;
  }
  .mobileAiPrompt {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
  }
  .mobileAlertTop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 80px;
    padding: 10px 20px;
  }
  .aiPromptButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }

  .aiPromptButtons a {
    text-decoration: none;
  }
  .aiPromptButtons p {
    margin: 0px;
  }
  .active-item {
    background-color: #00ba77;
    font-weight: 400;
    font-size: 13px;
    color: white;
    padding: 7px 0px;
    width: 75px;
    border-radius: 5px;
    cursor: pointer;
  }
  .active-item p {
    color: white;
    text-align: center;
  }
  .nonactive-item {
    font-weight: 400;
    font-size: 13px;
    padding: 7px 0px;
    width: 75px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f5f5f5;
  }
  .nonactive-item p {
    text-align: center;
    color: black;
  }
  .historyIcon {
    position: absolute;
    left: 18px;
  }
}
