.area-chart-lockup-container {
  width: 80%;
  height: 200px;
}

@media (max-width: 786) {
  .area-chart-lockup-container {
    width: 100%;
  }
}
