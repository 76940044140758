.alertLeftBottom {
  background-color: white;
  border-radius: 5px;
  overflow-y: auto;
  height: 84%;
  padding: 10px;
  margin: 2px 2px;
}
.alertLeftBottom h4 {
  font-weight: 500;
  font-size: 16px;
  color: #515151;
  margin: 10px 0px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th {
  background-color: #f5f5f5;
  color: black;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
}

.custom-table td,
.custom-table th {
  border: 0.5px solid #f0f0f0;
  padding: 8px;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

.custom-table img {
  padding: 5px;
}

.green-div {
  color: #00ba77;
}

.red {
  color: #ff5348;
}
