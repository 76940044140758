#notif {
  width: 350px;
  /* padding: 1rem; */
  overflow-y: scroll;
  font-size: 12px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  border: 1px solid #9c9c9c; /* Firefox */
}

#notif::-webkit-scrollbar {
  display: none;
}

.liveDemoContainer {
  border: 1px solid #00ba77;
  width: 120px;
  height: 30px;
  border-radius: 3px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: space-between;
}

.liveDiv {
  border-radius: 3px;
  padding-right: 10px;
}

.demoDiv {
  border-radius: 3px;
  padding-left: 10px;
}

.liveDiv p,
.demoDiv p {
  margin: 0px;
  padding: 0px 5px;
}

.activeLiveDemo {
  background-color: #00ba77;
  color: white;
  border-radius: 15px;
  padding: 6px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  background-color: white;
  height: 58px;
}
.left-header {
  display: none;
}

.left-side {
  padding: 5px;
}

.width-25 {
  width: calc(25% - 10px);
}

.pl {
  padding-left: 20px;
}

.left-side p {
  margin-left: 10px;
  font-size: 22px;
  font-weight: 500;
  margin: 0px;
}

.middle-header1 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.middle-header1 a {
  text-decoration: none;
}

.active-middle-item {
  display: flex;
  align-items: center;
  background: #00ba77;
  padding: 10px 25px;
  border-radius: 8px;
}

.nonactive-middle-item {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 0px 20px;
  border-radius: 8px;
}

.active-middle-item p {
  margin: 0px;
  text-align: center;
  font-weight: 400;
  color: white;
  font-size: 13px;
}

.nonactive-middle-item p {
  margin: 0px;
  text-align: center;
  font-weight: 400;
  color: black;
  font-size: 13px;
}

.right-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}
.bell-icon img {
  font-size: 22px;
  margin-right: 20px;
  margin-left: 20px;
  cursor: pointer;
}

.profile-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-imageContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 2px;
}

.profile-image {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
}

.dropdown-icon {
  font-size: 12px;
  margin-left: 5px;
}

.menu-icon {
  font-size: 30px;
  color: #00ba77;
}

.bell-container {
  /* width:50px;
  height: 50px; */
  position: relative;
  cursor: pointer;
}

.bell-icon {
  font-size: 22px;
  margin-right: 15px;
  margin-left: 15px;
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100px;
  padding: 12px 0px;
  top: 140px;
  right: 16px;
  z-index: 30;
}

.dropdown-item {
  padding: 8px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.dropdown-item:hover {
  background: #e4e4e4;
}

.dropdown-img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.dropdown-item p {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  color: black;
}

.search-bar {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #d9d9d94d;
  border-radius: 5px;
}

@media (max-width: 900px) {
  .search-bar {
    /* display: none !important; */
  }
}

.search-bar input {
  width: 100%;
  border: none;
  background: transparent;
  padding-left: 4px;
  color: #c3c3c3;
  outline: none;
}

input::placeholder {
  color: #c3c3c3;
}

.backAndTitle {
  display: none;
  align-items: center;
  margin-left: 5px;
}

.backAndTitle .back {
  cursor: pointer;
}

.backAndTitleLaptop {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.backAndTitleLaptop .back {
  cursor: pointer;
  padding-right: 10px;
}

@media (max-width: 900px) {
  .middle-header1 {
    /* display: none !important; */
  }
}
@media (max-width: 600px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 0px 15px;
    height: 50px;
  }
  .left-header {
    display: flex;
  }
  .left-header p {
    margin: 0px 0px 0px 5px;
    font-size: 16px;
    font-weight: 500;
  }
  .left-side {
    display: none;
  }

  #notif {
    right: 0;
    width: 300px;
  }
  .liveDemoContainer {
    display: none;
  }
}

@media (max-width: 480px) {
  .search-bar input {
    font-size: 14px;
  }
}
