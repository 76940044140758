/* holdingstable.css */
.holdingsTableContainer {
        /* overflow-x: auto; */
        background-color: white;
        border-radius: 5px;
        padding: 5px;
        margin-top: 5px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        height: calc(100dvh - 235px);

}

.paginate {
        background-color: green;
        color: white;
        height: 1.25rem;
        width: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.125rem;
        text-decoration: none;
}

.pagin {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        color: #151515;
        gap: 1rem;
        list-style-type: none;
}

.holdingsTableContainer::-webkit-scrollbar {
        display: none;
}

.instrument {
        color: #151515;
        font-size: 15px;
        font-weight: 500;
}

.holdingTableParent {
        height: calc(100dvh - 230px - 60px);
        scrollbar-width: none;
        overflow-y: scroll;
        width: 100%;
}

.holdingTable {

        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
        background-color: white;
        border-radius: 5px;
}


.holdingTable thead tr {
        border-top: none;
        border-bottom: 1px solid #f0f0f0;
}

.holdingTable .border {
        border-right: 1px solid #f0f0f0;
        border-left: 1px solid white;
}

.holdingTable tr {
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
}

.holdingTable thead th,
.holdingTable tbody td {
        padding: 14px;
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        color: #515151cc;
        text-align: center;
}

.holdingTable thead th {
        font-size: 13px;
        font-weight: 400;
        color: #515151cc;
}

.search-bar-holdings {
        display: flex;
        align-items: center;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        /* background: #d9d9d94d; */
}

.search-bar-holdings input {
        width: 100%;
        border: none;
        background: transparent;
        padding-left: 4px;
        color: #c3c3c3;
        outline: none;
}

input::placeholder {
        color: #c3c3c3;
}


@media screen and (max-width: 1393px) {
        .holdingsTableContainer {
                height: calc(100dvh - 400px);
        }

        .holdingTableParent {
                height: calc(100dvh - 400px - 60px);
        }

}