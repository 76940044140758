.otpCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 0;
}
.otpHeading {
  font-weight: 500;
  font-size: 25px;
}
.otpHeading h4 {
  margin-bottom: 0;
}
.otpText p {
  font-weight: 400;
  font-size: 14px;
}
.otpCards {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  column-gap: 18px;
  padding-bottom: 60px;
}
.card1 input::placeholder {
  color: black;
  font-size: 22px;
}
.card1 input {
  width: 45px;
  height: 60px;
  border: 0.5px solid #000000;
  border-radius: 5px;
  font-size: 30px;
  text-align: center;
}
.confirmButton {
  border: 0.5px solid #80808080;
  padding: 14px 185px;
  background: #00ba77;
  border-radius: 5px;
}
.confirmButton a {
  color: white;
}
.otpCardBottom {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}
.otpCardBottom p {
  font-weight: 400;
  font-size: 14px;
}
.resendText p {
  color: #00ba77;
  text-decoration: underline;
}
@media (max-width: 500px) {
  .otpCard {
    padding: 30px 0 200px;
  }
  .otpCards {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
  .card1 input {
    width: 50px;
    height: 55px;
    border: 0.5px solid #000000;
    border-radius: 5px;
  }
  .confirmButton {
    padding: 14px 130px;
  }
  .card1 input::placeholder {
    color: black;
    font-size: 20px;
  }
}
@media (max-width: 426px) {
  .otpText {
    text-align: center;
    width: 242px;
  }
  .otpCards {
    padding-bottom: 30px;
    column-gap: 8px;
  }
  .confirmButton {
    padding: 14px 100px;
  }
  .card1 input {
    width: 28px;
    height: 28px;
    border: 0.5px solid #000000;
    border-radius: 5px;
    font-size: 16px;
    padding: 0px;
  }
}
/* @media (max-width: 360px) {
  .card1 input {
    width: 40px;
    height: 45px;
    border: 0.5px solid #000000;
    border-radius: 5px;
  }
} */
