.viewimg {
  width: 100%;
  height: 300px;
  margin-top: 1rem;
}

.bankTransferContainer {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  padding: 90px 223px 150px;
}
.bankTransferHeading h4 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 0;
}
.bankTransferText {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bankTransferText p {
  width: 318px;

  text-align: center;
  font-weight: 300;
  font-size: 13px;
  color: #515151;
  padding-bottom: 20px;
}
.detailCard {
  position: relative;
  border: 0.5px solid #cccccce5;
  border-radius: 5px;
  padding: 20px 25px 40px;
}
.bankCard p {
  font-weight: 400;
  font-size: 13px;
  color: #484848;
}
.bankName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0.5px solid #cccccce5;
  border-radius: 5px;
  width: 92%;
  padding: 0 18px;
}
.bankName p {
  font-weight: 400;
  font-size: 16px;
  color: #151515;
}
.accountNoCard {
  padding-top: 10px;
}
.accountNoCard p {
  font-weight: 400;
  font-size: 13px;
  color: #484848;
}
.accountNo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0.5px solid #cccccce5;
  border-radius: 5px;
  width: 92%;
  padding: 0 18px;
}
.accountNo p {
  font-weight: 400;
  font-size: 16px;
  color: #151515;
}
.accountNameCard {
  padding-top: 10px;
}
.accountNameCard p {
  font-weight: 400;
  font-size: 13px;
  color: #484848;
}
.accountName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 0.5px solid #cccccce5;
  border-radius: 5px;
  width: 92%;
  padding: 0 18px;
}
.accountName p {
  font-weight: 400;
  font-size: 16px;
  color: #151515;
}
.paymentButton p {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 0.5px solid #cccccce5;
  color: white;
  background: #00ba77;
  margin-top: 25px;
  padding: 13px 50px;
  cursor: pointer;
}
.bankName img,
.accountNo img,
.accountName img {
  cursor: pointer;
}
.tooltip {
  position: absolute;
  top: 10px; /* Adjust the distance above the copied icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: #00ba77;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0.9;
  transition: opacity 0.5s ease-in-out;
}

.tooltip.hide {
  opacity: 0;
  pointer-events: none; /* Disable pointer events when hidden */
}

@media (max-width: 768px) {
  .bankTransferContainer {
    padding: 90px 80px 150px;
  }
}
@media (max-width: 600px) {
  .bankTransferHeading {
    font-weight: 500;
    font-size: 18px;
  }
  .bankName {
    width: 92%;
  }
  .accountNo {
    width: 92%;
  }
  .accountName {
    width: 92%;
  }
  .paymentButton p {
    padding: 13px 20px;
  }
}
@media (max-width: 426px) {
  .bankTransferContainer {
    padding: 30px 10px 100px;
  }
  .bankTransferText p {
    width: 260px;
  }
  .detailCard {
    padding: 10px 10px 15px;
  }
  .accountNameCard {
    padding-top: 3px;
  }
  .accountNoCard {
    padding-top: 3px;
  }
  .bankName {
    width: 89%;
  }
  .accountNo {
    width: 89%;
  }
  .accountName {
    width: 89%;
  }
  .paymentButton p {
    padding: 13px 10px;
  }
}

@media (min-width: 1440px) {
  .rounded-card-container {
    display: flex;
    justify-content: center;
  }
  .rounded-card {
    max-width: 1200px;
  }
}
