.walletTableContainer {
  margin-top: 20px;
  padding: 20px 10px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
}
.walletTableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.walletHeaderLeft p {
  font-weight: 500;
}
.walletHeaderRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.walletSearchBar {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* background: #d9d9d94d; */
}

.walletSearchBar input {
  width: 100%;
  border: none;
  background: transparent;
  padding-left: 4px;
  color: #838383;
  outline: none;
}

input::placeholder {
  color: #c3c3c3;
}
.selectFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 7px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
}
.selectFilter p {
  margin: 0px;
}

.dropdownFilter {
  position: absolute;
  top: 35px;
  right: 0;
  width: 150px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.filterOption {
  padding: 8px;
  cursor: pointer;
}

.filterOption:hover {
  background-color: #ddd;
}

.walletTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 5px;
}

.walletTable tbody {
  border-radius: 10px;
  border: 1px solid #f0f0f0;
}
.walletTable thead tr {
  border-top: none;
  border-bottom: 1px solid #f0f0f0;
}

.walletTable tr th {
  cursor: pointer;
}

.walletTable .border {
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid white;
}

.walletTable tr {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.walletTable thead th,
.walletTable tbody td {
  padding: 14px;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  color: #515151cc;
}

.walletTable thead th {
  font-size: 13px;
  font-weight: 400;
  color: #515151cc;
}

.bgSuccess {
  background: #0fb23c1a;
  padding: 4px 8px;
  width: fit-content;
  border-radius: 20px;
}

.bgSuccess p {
  color: #018b28;
  font-weight: 400;
  margin: 0px;
}

.textSuccess {
  color: #018b28;
  font-weight: 400;
  margin: 0px;
}

.bgPending {
  background: #e7a6001a;
  padding: 4px 8px;
  width: fit-content;
  border-radius: 20px;
}

.bgPending p {
  color: #e7a600;
  font-weight: 400;
  margin: 0px;
}

.bgFailed {
  background: #b20f191a;
  padding: 4px 8px;
  width: fit-content;
  border-radius: 20px;
}

.bgFailed p {
  color: #b20f19;
  font-weight: 400;
  margin: 0px;
}

.textFailed {
  color: #b20f19;
  font-weight: 400;
  margin: 0px;
}

.paginationContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0px 10px;
}
.paginationContainer .pages span {
  color: #16161699;
  font-size: 14px;
}
.pagination {
  display: flex;
  justify-content: center;
}

.pagination span {
  padding: 0px 16px;
  font-size: 14px;
}
.pagination button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.active {
  background: #00ba77 !important;
  color: white;
}

@media (max-width: 426px) {
  .walletTableWrapper {
    overflow-x: auto;
  }
  .walletTable {
    margin: 10px 0px;
  }
  .walletTableHeader {
    flex-direction: column;
  }
  .walletHeaderLeft {
    display: flex;
    justify-content: flex-start;
    width: -webkit-fill-available;
  }
  .walletHeaderRight {
    width: -webkit-fill-available;
    flex-direction: column;
  }
  .walletSearchBar,
  .selectFilter {
    width: -webkit-fill-available;
    justify-content: center;
    padding: 10px;
  }
  .paginationContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    margin-top: 10px;
    gap: 20px;
  }
  .pages {
    display: flex;
    justify-content: center;
  }
}
