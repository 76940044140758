.paystack_main_container {
  padding: 2rem;
}

.paystack_container {
  background-color: white;
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.paystack_container h1 {
  text-align: center;
  color: #151515;
  font-size: 1.5625rem;
  font-weight: 500;
}

.paystack_container p {
  text-align: center;
  width: 15rem;
  color: #515151;
  font-size: 0.8125rem;
  margin-bottom: 2rem;
}

.paystack_form {
  width: 31.3125rem;
  border: 1px solid #cccccce5;
  border-radius: 0.3125rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.paystack_form div {
  display: flex;
  flex-direction: column;
}

.paystack_amount {
  padding: 0.92rem 1.25rem;
  border: 1px solid #cccccce5;
  border-radius: 0.3125rem;
}

.paystack_label {
  color: #484848;
  font-size: 0.8125rem;
  margin-bottom: 0.88rem;
}

.paystack_paymentButton {
  width: 31.3125rem;
  background-color: #00ba77;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  border: none;
  color: white;
  padding: 0.8rem 0;
}

.paystack_paymentButton p {
  text-align: center;
}

@media (max-width: 426px) {
  .paystack_main_container {
    padding: 0.5rem;
  }
  .paystack_form {
    width: 70%;
  }

  .paystack_container {
    height: 70vh;
  }

  .paystack_paymentButton {
    width: 70%;

  }
}
