.holdingCard {
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
}
.holdingCardTop {
  display: flex;
  flex-direction: row;
}
.holdingCardLeft {
        width: 100%;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
}

/* .holdingCardLeft > div{
        flex: 1;
} */
.headingIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #00ba77;
}
.headingIcon svg {
  width: 20px;
  height: 20px;
}
.holdingCardRight {
  width: 67%;
  background-color: white;
  margin: 2px;
  border-radius: 5px;
}
.pieContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.pieChartContainer {
  height: 400px;
  width: 400px;
  margin: 20px;
}
.portfolioValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2px;
  background-color: #ffffff;
  padding-bottom: 40px;
  position: relative;
  border-radius: 5px;
}
.portfolioValue p {
  font-size: 16px;
  font-weight: 400;
}
.totalInvested p {
  font-size: 16px;
  font-weight: 400;
}
.profitLoss p {
  font-size: 16px;
  font-weight: 400;
}
.totalInvested {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin: 2px;
  padding-bottom: 40px;
  border-radius: 5px;
  position: relative;
}
.profitLoss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin: 2px;
  padding-bottom: 40px;
  border-radius: 5px;
  position: relative;
}
.n-text p {
  margin: 0;
  font-size: 35px;
  color: black;
  font-weight: 400;
  line-height: 76.56px;
  font-family: "Space Grotesk", sans-serif;
}
.o-text p {
  margin: 0;
  font-size: 35px;
  color: #00ba77;
  font-weight: 400;
  line-height: 76.56px;
  font-family: "Space Grotesk", sans-serif;
}
.selectOption {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.selectOption select {
  border: none;
  outline: none;
}
@media (max-width: 426px) {
  .holdingCardTop {
    display: flex;
    flex-direction: column;
  }
  .pieChartContainer {
    height: 300px;
    width: 300px;
    margin: 20px;
  }
  .holdingCardLeft {
    width: 100%;
  }
  .holdingCardRight {
    width: 100%;
    background-color: transparent;
  }
  .portfolioValue {
    padding-bottom: 10px;
  }
  .totalInvested {
    padding-bottom: 10px;
  }
  .profitLoss {
    padding-bottom: 10px;
  }
}

@media (min-width: 1440px) {
  .holdingCardContainer {
    display: flex;
    justify-content: center;
  }
  .holdingCard {
    max-width: 1300px;
    width: 100%;
  }
}
