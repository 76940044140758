.sidebar-container {
  /* width: 73px; */
  width: fit-content;
  /* height: calc(100vh - 40px); */
  /* height: -webkit-fill-available; */
  background-color: #02915d;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-grow: 1;
    overflow-y: auto; */
}

.user-profile-container {
  cursor: pointer;
  background-color: #e8e5e5;
  border-radius: 100%;
  padding: 0 5px;
  width: 60px;
  height: 60px;
}
.user-profile {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #d9d9d9;
}

.sidebar-menu-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 13px 0px;
  text-decoration: none;
}

.item-name {
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
}

.grey {
  color: #dddddd;
}

.white {
  color: white;
}

.sidebar-item-list {
  padding-top: 10px;
}

.item-list img {
  width: 25px;
  height: 25px;
}
@media (max-width: 600px) {
  .sidebar-container {
    display: none;
  }
}
