.accountInformationContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 60px;
  padding-top: 12px;
  height: calc(100dvh - 60px - 54px);

}
.accountInformationContainer a {
  text-decoration: none;
  color: black;
}

.accountInformationCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* max-width: 800px; */
  width: 100%;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 20px 30px;
  margin: 0px;
  cursor: pointer;
}
.accountInformationCard a {
  font-size: 16px;
  font-weight: 400;
}
.accountRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 25px;
  align-items: center;
}
.completeText {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}
.completeText p {
  font-weight: 400;
  font-size: 13px;
  color: #00ba77;
}
.incompleteText {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
}
.incompleteText p {
  font-weight: 400;
  font-size: 13px;
  color: #ff5348;
}
.completeAccountInformation {
  background-color: #02915d1a;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  border-radius: 5px;
}
.completeAccountInformation p {
  font-weight: 400;
  font-size: 14px;
}
.arrowIcon FaChevronRight {
  font-weight: 100;
}
@media (max-width: 768px) {
  .accountInformationCard {
    max-width: 550px;
  }
}
@media (max-width: 426px) {
  .accountInformationCard {
    padding: 8px 10px;
  }
  .accountInformationContainer {
        height: calc(100dvh - 60px - 40px);
}
  .accountInformationCard a {
    font-weight: 400;
    font-size: 14px;
  }
  .accountRight {
    column-gap: 3px;
  }
  .completeText {
    column-gap: 2px;
  }
  .completeText p {
    font-size: 12px;
  }
  .completeText img {
    width: 15px;
    height: 15px;
  }
  .incompleteText img {
    width: 15px;
    height: 15px;
  }
  .incompleteText p {
    font-size: 12px;
  }
  .incompleteText {
    column-gap: 2px;
  }
  .completeAccountInformation p {
    text-align: center;
  }
}
@media (max-width: 385px) {
 
  .accountInformationContainer {
    padding: 0 15px 100px;
  }
}
@media (max-width: 340px) {
  
  .accountInformationContainer {
    padding: 0 15px 100px;
  }
}
