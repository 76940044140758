.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . .";
  list-style: none;
  border-bottom: 1px solid #d3d3d3;
  padding: 0px 20px;
}

.grid a {
  text-decoration: none;
  color: black;
}

.grid-item {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.grid-item a {
  text-decoration: none;
  color: black;
}

.grid-active {
  border-bottom: 2px solid #02915d;
  padding-bottom: 15px;
}
.grid-nonactive {
  border-bottom: 2px solid transparent;
  padding-bottom: 15px;
}

.item-name {
  text-align: center;
  /* margin: 15px; */
}

.block {
  display: block;
}
.hidden {
  display: none;
}
