.delete-confirmation-popup {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

.button-container {
  margin-top: 15px;
}

button {
  margin: 0 10px;
  padding: 8px 16px;
  cursor: pointer;
}

button:hover {
  background-color: #ddd;
}
