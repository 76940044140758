.savingLockCard {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 200px;
}
.savingLockTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}
.savingLockHeading {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  padding-top: 10px;
}
.savingLockHeading h4 {
  font-size: 30px;
  font-weight: 500;
  margin: 0;
}
.createButton {
  margin-top: 10px;
}
.createButton a {
  background: #02915d;
  color: white;
  padding: 8px 18px;
  border-radius: 5px;
  text-decoration: none;
}
.savingLockBottom {
  display: flex;
  flex-direction: row;
  column-gap: 25px;
}
.savingLockLeft {
  width: 45%;
}
.lockWalletCard {
  display: flex;
  flex-direction: column;
  border: 0.5px solid #cccccce5;
  border-radius: 5px;
  padding: 7px 20px;
}
.lockWalletTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.interestRateText {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 15px;
}
.interestRateText p {
  font-weight: 500;
  font-size: 16px;
}
.interestRateText h4 {
  color: #00ba77;
  font-weight: 300;
  font-size: 30px;
}
.noText {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.noText p {
  margin: 0;
  font-size: 50px;
  color: #00ba77;
  font-weight: 400;
  line-height: 76.56px;
  font-family: "Space Grotesk", sans-serif;
}
.contentdiv p {
  /* margin-bottom: 0; */
  font-size: 12px;
  color: #00ba77;
}

.savingOptions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 30px;
}

.savingLockRight {
  border: 0.5px solid #cccccce5;
  border-radius: 5px;
  padding: 7px 0px;
  width: 49%;
}

.savingHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}

.active-middle-item {
  display: flex;
  align-items: center;
  background: #00ba77;
  padding: 10px 25px;
  border-radius: 8px;
  cursor: pointer;
}

.nonactive-middle-item {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 0px 20px;
  border-radius: 8px;
  cursor: pointer;
}

.active-middle-item p {
  margin: 0px;
  text-align: center;
  font-weight: 400;
  color: white;
  font-size: 13px;
}

.nonactive-middle-item p {
  margin: 0px;
  text-align: center;
  font-weight: 400;
  color: black;
  font-size: 13px;
}

.lock-table {
  width: 100%;
  border-collapse: collapse;
}

.lock-table th {
  background-color: white;
  color: black;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  border-right: none;
}

.lock-table td,
.lock-table th {
  border: 0.5px solid #f0f0f0;
  padding: 8px;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}

@media (max-width: 600px) {
  .savingLockLeft {
    width: 100%;
  }
  .savingLockBottom {
    display: flex;
    flex-direction: column;
  }
  .savingLockRight {
    width: 100%;
  }
  .interestRateText h4 {
    font-size: 16px;
  }
}
@media (max-width: 426px) {
  .savingLockCard {
    padding: 20px 10px 200px;
  }

  .savingLockHeading h4 {
    font-weight: 500;
    font-size: 16px;
  }
  .savingLockHeading img {
    height: 20px;
    width: 20px;
  }
  .createButton a {
    font-size: 12px;
    font-weight: 400;
  }

  .lockWalletCard {
    padding: 5px 15px;
  }
}
