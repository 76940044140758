.user-profile-container {
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;

}
.user-profilea {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #d9d9d9;
}

.menu-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  text-decoration: none;
}

.item-name {
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
}

.grey {
  color: #dddddd;
}

.white {
  color: white;
}

.item-list {
  padding-top: 10px;
}

.item-list img {
  width: 20px;
  height: 20px;
}

.mobileLiveDemoContainer {
  margin-top: auto;
  margin-bottom: 120px;
  border: 1px solid #00ba77;
  padding: 2px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.mobileLiveDiv {
  border-radius: 3px;
  width: -webkit-fill-available;
}

.mobileDemoDiv {
  border-radius: 3px;
  width: -webkit-fill-available;
}

.mobileDemoDiv p,
.mobileLiveDiv p {
  margin: 0px;
  padding: 8px 5px;
  text-align: center;
}

.activeMobileLiveDemo {
  background-color: #00ba77;
  color: white;
  border-radius: 5px;
}

@media (max-width: 600px) {
        .sidebar-container {
                display: none;
        }

         .item-list > div {
                transition:all .35s ease-in-out ;
        }

        .item-list > div:hover {
                transform: scale(.9);
        }
}
