.alertRightTextCard {
  width: 100%;
  overflow-y: auto;
}
.alertRightCard {
  flex: 1;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 2px;
  height: 100%;
}
.alertManualRight {
  width: 100%;
  display: flex;
}
.alertRightManualCard {
  width: 100%;
}

@media (max-width: 340px) {
  .aboveSelect select {
    width: fit-content;
  }
}
