.alert-right {
  width: 100%;
  display: flex;
  height: calc(100vh - 60px);
}
.alert-rightCard {
  flex: 1;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 2px;
  height: 100%;
  width: 100%;
}
.alertRightText {
  display: flex;
  justify-content: center;
}
.alertRightHeader p {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  width: 100%;
}
.alert-rightTextCard {
  /* width: 100%; */
  overflow-y: auto;
  padding: 0px 15px;
}

ol {
  padding-left: 0px; /* Adjust the value according to your preference */
}

.alert-rightTextCard.scrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alert-rightTextCard.scrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

.alert-rightTextCard.scrolling::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alert-rightTextCard.scrolling::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alert-rightTextCard.notscrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alert-rightTextCard.notscrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
  background-color: transparent; /* Change the color as needed */
}

.alert-rightTextCard.notscrolling::-webkit-scrollbar-thumb {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alert-rightTextCard.notscrolling::-webkit-scrollbar-track {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertRightText p {
  font-weight: 400;
  font-size: 13px;
  padding: 15px 25px;
  max-width: 400px;
  background: #f0f0f0;
  border-radius: 5px;
}
