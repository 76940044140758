.alertCard {
  display: flex;
  flex-direction: row;
}
.alertLeft {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: calc(100vh - 60px);
}
.alertRight {
  width: 75%;
  display: flex;
  height: calc(100vh - 60px);
}
.alertRightCard {
  flex: 1;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 2px;
  height: 100%;
}
.alertLeftTop {
  /* overflow-y: auto;
  height: 70%; */
}
.alertLeftBottom {
  background-color: white;
  border-radius: 5px;
  overflow-y: auto;
  height: 84%;
  padding: 10px;
  margin: 5px 2px;
}
.alertLeftBottom::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

.alertLeftBottom::-webkit-scrollbar-thumb {
  background-color: transparent; /* Change the color as needed */
  border-radius: 4px;
  background: #ccc;
}

.alertLeftBottom::-webkit-scrollbar-track {
  background-color: white; /* Change the color as needed */
  border-radius: 4px;
}
.alertLeftBottom h4 {
  font-weight: 500;
  font-size: 16px;
  color: #515151;
  margin: 10px 0px;
}
.alertRightTextCard {
  width: 100%;
  overflow-y: auto;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th {
  background-color: #f5f5f5;
  color: black;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
}

.custom-table td,
.custom-table th {
  border: 0.5px solid #f0f0f0;
  padding: 8px;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
}
.textArea {
  border: 0.5px solid #80808080;
  border-radius: 5px;
  width: 50%;
  padding: 5px 10px;
}

.custom-table img {
  padding: 5px;
}

@media (min-width: 426px) {
  .mobileManual {
    display: none;
  }
}
@media (max-width: 768px) {
  .alertLeft {
    width: 35%;
  }

  .alertRight {
    width: 65%;
  }
}
@media (max-width: 426px) {
  .alertCard {
    display: none;
  }

  .mobileManual {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
  }
  .mobileAlertTop {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 80px;
    padding: 10px 20px;
  }
  .aiPromptButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }

  .aiPromptButtons a {
    text-decoration: none;
  }
  .aiPromptButtons p {
    margin: 0px;
  }
  .active-manualitem {
    background-color: #00ba77;
    font-weight: 400;
    font-size: 13px;
    color: white;
    padding: 7px 25px;
    border-radius: 5px;
    cursor: pointer;
  }
  .active-manualitem p {
    color: white;
  }
  .nonactive-manualitem {
    font-weight: 400;
    font-size: 13px;
    padding: 7px 40px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f5f5f5;
  }
  .nonactive-manualitem p {
    color: black;
  }
}
