.app {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #15151580;
  z-index: 2;
}

.sidebar {
  background: #03915d;
}

.popup-sidebar {
  display: flex;
  flex-direction: column;
  background-color: #02915d;
  width: fit-content;
  padding: 20px 10px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 4;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100vh;
}

.open {
  transform: translateX(0);
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 24px;
  color: white;
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  margin: 0;
}

.menu-button:focus {
  outline: none;
}

.content {
  /* flex: 1;
  overflow: auto; */
  flex-grow: 1;
  overflow-y: auto;
  /* background-color: white; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app__flex{
        display: flex;
        justify-content: center;
        align-items: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ph-l {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

@media (max-width: 478px) {
        .content {
                height: fit-content;
        }
}
@media (min-width: 601px) {
  .popup-sidebar {
    display: none;
  }
  .overlay {
    display: none;
  }
}

