.marketContainer {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 58px);
  width: 100%;
  /* background: red; */
}

.newGrid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr;
  gap: 12px;
}
.expandedMarketContainer {
  /* height: calc(100vh - 98px); */
  background: white;
  padding: 20px;
}

.technicalExtras {
  background: white;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  box-shadow: 0px 0px 5px 1px rgba(0.1, 0.1, 0.1, 0.1);
}

.leftMarket {
  width: calc(25% - 2px);
  min-width: 300px;
  overflow-y: auto;
  margin: 2px;
  background: white;
}

.middleMarket {
  /* height: calc(100vh - 58px); */
  /* height: calc(100vh - 305px); */
  /* height: calc(100vh - 304px); */
  /* height: 60%; */
  background: white;
  overflow-y: scroll;
  /* background: yellow; */
}
.width75 {
  width: 75%;
}
.width45 {
  width: 45%;
}
.rightMarket {
  /* width: 30%; */
  /* background: #00ba77; */
  width: 0;
  overflow-y: auto;
  margin: 2px;
}

.stockItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  padding: 10px 15px;
  cursor: pointer;
}

.stockItem:hover {
  background: #f5f5f5;
}

.stockName {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.stockName .name {
  font-weight: 400;
  /* font-size: 14px; */
  color: #151515;
  margin: 0px;
}

.stockName .pprice {
  color: #00ba77;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.stockName .nprice {
  color: #ff5348;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.marketsNoTextEquity {
  display: flex;
  justify-content: center;
}

.no-text {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.nTextMarkets p {
  margin: 0;
  font-size: 50px;
  color: black;
  font-weight: 400;
  line-height: 76.56px;
  font-family: "Space Grotesk", sans-serif;
}
.om-text .price {
  margin: 0;
  font-size: 50px;
  color: #00ba77;
  font-weight: 400;
  line-height: 76.56px;
  font-family: "Space Grotesk", sans-serif;
}

.percent {
  display: flex;
  align-items: flex-end;
  color: #00ba77;
  font-size: 13px;
}

.stockInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border-top: 1px solid #cccccc;
}

.stockDetail {
  display: flex;
  justify-content: space-between;
}

.stockDetail img {
  width: 50px;
  height: 50px;
}

.nameAndDesc {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 5px;
}

.nameAndDesc .name {
  font-size: 22px;
  font-weight: 500;
}

.nameAndDesc .description {
  font-size: 14px;
}

.nameAndDesc p {
  margin: 0px;
}

.middle-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0px 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;
}

@media (max-width: 600px) {
        .middle-header {
                justify-content: center;
        }
}

.mainAreaContainer {
  display: flex;
  /* display: flex;
  align-items: center;
  flex-direction: column;
  flex-direction: column; */
  position: relative;
  /* height: calc(100vh - 300px); */
  /*   height: 53vh; */

  /* height: 53vh; */
  /*   height: 50%; */
  /* overflow-y: scroll; */
  /* padding: 50px 0px 50px 15px; */
}

.mainAreaContainerExpanded {
  display: flex;
  align-items: center;
  /* height: calc(100vh - 267px); */
  padding: 0px;
}

.lotCalculation {
  border: 0.5px solid #151515;
  border-radius: 8px;
}

.lotCalculation p {
  margin: 0px;
  text-align: center;
  color: #8b8b8b;
}

.lotCalculation button {
  background: transparent;
  border: none;
  color: #00ba77;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.lotCalculation input {
  border: none;
  outline: none;
  width: 40px;
  text-align: center;
}

.orderBookContainer {
  margin: 2px;
  background: white;
  border-radius: 5px;
  height: 190px;
}

.orderBook {
  margin: 2px;
  background: white;
  border-radius: 5px;
}

.orderBookHeader {
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.orderBookHeader .closeButton {
  cursor: pointer;
  padding-right: 5px;
}

.orderBookHeader p {
  margin: 0px;
  font-weight: 400;
  padding: 10px 5px;
}

.orderBookChart {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* overflow: hidden; */
}

.orderBookChart canvas {
  width: 100% !important;
  height: 130px !important;
}

.asks {
  position: relative;
}
.bids {
  position: relative;
}
.tradeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 5px 20px;
}

.buyButton {
  background: #00ba77;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sellButton {
  background: #ff5348;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyButton p {
  margin: 8px;
}

.sellButton p {
  margin: 8px;
}

.nonActiveButton {
  background: #f5f5f5;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nonActiveButton p {
  color: #515151;
  margin: 8px;
}

.orderType p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
}
.orderTypeSelect {
  padding: 8px;
  background-color: transparent;
  border-radius: 5px;
  border: 0.3px solid #808080;
}

.orderTypeSelect select {
  background-color: transparent;
  font-size: 13px;
  width: -webkit-fill-available;
  border: none;
  outline: none;
}

.orderTypeSelect input {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 13px;
  width: 100%;
}

.timeInForce {
  padding: 0px 20px;
}

.timeInForce p {
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0px;
}

.mainButton {
  padding: 10px 20px;
}

.bidAskHeadings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30;
}

.bidSize {
  font-size: 12px;
  font-weight: 400;
  margin: 5px;
}

.askSize {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  margin: 5px;
}

.askBG {
  background-color: #ff53481a;
  border: 0.5px solid white;
  padding-left: 10px;
}

.bidBG {
  background-color: #00ba771a;
  border: 0.5px solid white;
  padding-right: 10px;
}

.bidText {
  font-size: 12px;
  color: #00ba77;
  text-align: right;
  margin: 5px;
}
.askText {
  font-size: 12px;
  color: #ff5348;
  text-align: left;
  margin: 5px;
}

.bidAskContainer {
  margin: 2px;
  background: white;
  border-radius: 5px;
  padding: 10px;
}

.bidAskItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.marketsMobile {
  display: none;
}

/* .date {
  background: #00ba7799;
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
} */

.closePrice {
  background: #ffffff99;
  padding: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media (max-width: 900px) {
  .marketsLaptop {
    display: none;
  }
  .marketsMobile {
        background-color: #fff;
    display: block;
    height: 100vh;
  }
  .leftMarket {
    width: 100%;
    margin: 0px;
  }
  .mobileStockMarkets {
    background: white;
    margin: 2px;
    /* height: calc(100vh - 55px); */
    /* min-height: -webkit-fill-available; */
    /* width:fill-available; */
  }
  .aiPromptButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }

  .aiPromptButtons a {
    text-decoration: none;
  }
  .aiPromptButtons p {
    margin: 0px;
  }
  .active-item {
    background-color: #00ba77;
    font-weight: 400;
    font-size: 13px;
    color: white;
    padding: 7px 40px;
    border-radius: 5px;
    cursor: pointer;
  }
  .active-item p {
    color: white;
  }
  .nonactive-item {
    font-weight: 400;
    font-size: 13px;
    padding: 7px 25px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f5f5f5;
  }
  .nonactive-item p {
    color: black;
  }

  .stockInfo {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #cccccc;
  }

  .marketsNoTextEquity{
        margin-left: 60px;
        margin-top: 5px;
  }
  .no-text {
    display: flex;
    flex-direction: row;
    /* column-gap: 10px; */
  }
  .nTextMarkets,
  .om-text {
    display: flex;
    align-items: flex-end;
  }
  .nTextMarkets p {
    font-size: 20px;
    font-weight: bold;
    line-height: inherit;
  }
  .om-text .price {
    font-size: 20px;
    line-height: inherit;
    font-weight: bold;
  }
  .percent {
    font-size: 10px;
    align-items: center;
    margin-bottom: 0px;
  }
  .mainAreaContainerMobile {
    /* display: flex;
    align-items: center; */
    height: 100px;
    padding: 0px 10px 20px 10px;
    margin-top: 20px;
  }
  .rightMarket {
    width: calc(100% - 10px);
    margin: 0px 5px;
  }
}
