.middleHeader {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-left: 5px;
}

.pNormal {
  padding: 0px 30px;
}

.pExpand {
  padding: 10px 0px;
}

.buySmallButton {
  display: flex;
  align-items: center;
  background: #00ba77;
  padding: 0px 30px;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.buySmallButton p {
  margin: 8px;
}

.sellSmallButton {
  display: flex;
  align-items: center;
  background: #ff5348;
  padding: 0px 30px;
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.sellSmallButton p {
  margin: 8px;
}

.lotCalculation {
  border: 0.5px solid #151515;
  border-radius: 8px;
}

.lotCalculation p {
  margin: 0px;
  text-align: center;
  color: #8b8b8b;
}

.lotCalculation button {
  background: transparent;
  border: none;
  color: #00ba77;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.lotCalculation input {
  border: none;
  outline: none;
  width: 40px;
  text-align: center;
}

@media (max-width: 426px) {
  .pNormal {
    padding: 0px 20px;
  }
  .middleHeader {
    justify-content: center;
  }
  .buySmallButton {
    padding: 0px 20px;
  }
  .sellSmallButton {
    padding: 0px 20px;
  }
}
