@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");

.rounded-card {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.walletCard {
  padding: 20px 30px 30px;
}
.walletCardsContainerFirst {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.walletCardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.hr {
  margin: 30px 0px;
  border-color: #d8d8d8;
}
.walletCardHeader {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding: 20px 0px;
}
.walletCardHeader p {
  margin: 5px;
  font-weight: 500;
}
.wallet,
.savings {
  cursor: pointer;
}
.borderGreen {
  border-bottom: 2px solid #00ba77;
}
.amount-container {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 10px;
  border: 1px solid #00ba77;
  border-radius: 5px;
  background-color: #00ba771a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 180px;
}

.amount-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #d8d8d8;
}

.amount-card-header .heading {
  color: black;
  font-weight: 500;
  margin: 0px;
  font-size: 16px;
}

.resultChart {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.amount-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.amount-row p {
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: black;
}

.funds-container {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 10px;
  border: 1px solid #0fb23c;
  border-radius: 5px;
  background-color: #0fb23c1a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 180px;
}

.funds-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #d8d8d8;
}

.funds-card-header .heading {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

.funds-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.funds-row p {
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #000;
}

.reserved-container {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background-color: #f1f1f1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 180px;
}

.reserved-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #d8d8d8;
}

.reserved-card-header .heading {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}

.reserved-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.reserved-row p {
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #000;
}

.addFundsWithdraw {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.wallet-balance-card-buttons {
  display: flex;
  gap: 32px;
}
.addFundsWithdrawMobile {
  display: none;
}

.wallet-amount-card-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.withdraw {
  padding: 8px 12px;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #F1F1F1;
}
.withdraw img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.withdraw a {
  text-decoration: none;
  color: black;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}
.add-funds {
  padding: 10px 12px;
  border-radius: 5px;
  background-color: #00ba77;
  display: flex;
  align-items: center;
}
.add-funds img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.add-funds a {
  margin: 0;
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.bannerContainer {
  display: flex;
  justify-content: center;
}

.locked-container {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 10px 15px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background-color: #f1f1f1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 180px;
}

.locked-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #d8d8d8;
}

.locked-card-header .heading {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.locked-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.locked-row p {
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.lockupHistory {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.lockupHistory p {
  margin: 0px;
  color: #00ba77;
  font-weight: bold;
  font-size: 16px;
}

.savingsCalculations {
  margin-top: 20px;
  padding: 20px 10px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.leftSavings {
  padding: 20px;
}
.topLeftSavings {
  display: flex;
  align-items: center;
}
.topLeftSavings p {
  margin: 0px;
  display: flex;
  align-items: center;
  color: #16161699;
}
.amountToSave {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.amountToSave input {
  width: 100%;
  padding-left: 4px;
  font-size: 24px;
  font-weight: 700;
  color: #000;
  outline: none;
  border: none;
}

.topLeftSavings1 {
  display: flex;
  align-items: center;
  padding: 14px 0px;
}
.topLeftSavings1 p {
  margin: 0px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #16161699;
}
.slidecontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.slidecontainer p {
  width: 60px;
  text-align: right;
  color: #16161699;
  font-weight: 400;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.walletCardsLaptop {
  display: block;
}
.walletCardsMobile {
  display: none;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 0px 0px 2px 1px #00ba77;
  appearance: none;
  width: 40px;
  height: 40px;
  border: 2px solid #04aa6d;
  border-radius: 25px;
  background: white;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

.topLeftSavings span {
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.addFundsSavings {
  /* padding: 8px 12px;
  border-radius: 5px; */
  display: flex;
  align-items: center;
}
.addFundsSavings img {
  width: 20px;
  height: 20px;
  margin: 0px 8px;
  font-weight: 400;
}
.addFundsSavings a {
  margin: 0;
  color: #00ba77 !important;
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.rightSavings {
  padding: 0px 20px;
  border-left: 1px solid #d8d8d8;
}

.rightSavings .head {
  font-weight: bold;
  font-size: 16px;
}

.topRightSavings {
  display: flex;
  align-items: center;
}
.topRightSavings p {
  margin: 0px;
  display: flex;
  align-items: center;
  color: #16161699;
}
.topRightSavings span {
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #000;
}

.topRightSavings1 {
  display: flex;
  align-items: center;
}
.topRightSavings1 p {
  margin: 0px;
  display: flex;
  align-items: center;
  color: #16161699;
}

.topRightSavings1 span {
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #00ba77;
}
.addPadding {
  padding: 10px 0px;
}
.bottomRightSavings {
  margin-top: 10px;
  /* padding: 10px 0px; */
  font-size: 24px;
  font-weight: 700;
  color: black;
}
.bottomRightSavings p {
  margin: 0px;
}
.bottomRightSavings .greenBold {
  padding: 10px 0px;
  font-size: 24px;
  font-weight: 700;
  color: #00ba77;
}
.calculateContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.lockupSaveContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.lockupSave {
  padding: 8px 12px;
  border-radius: 5px;
  background-color: #00ba77;
  cursor: pointer;
}
.lockupSave p {
  margin: 0;
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.banner {
  background: #00ba77;
  width: 95%;
  height: 150px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.coin1 {
  position: absolute;
  top: -11px;
  right: -18px;
  width: 137px;
}
.coin2 {
  position: absolute;
  right: 14%;
  bottom: -10px;
  width: 95px;
}
.coin3 {
  position: absolute;
  right: 23%;
  bottom: 50px;
  top: 50px;
  width: 60px;
}
.coin4 {
  position: absolute;
  left: 16%;
  bottom: 10px;
  width: 20px;
}
.coin5 {
  position: absolute;
  left: 23%;
  top: 10px;
  width: 20px;
}
.coin6 {
  position: absolute;
  left: 30%;
  bottom: -1px;
  width: 20px;
}
.bannerContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width :800px;
  align-items: center;
  padding: 0px 20px;
  gap: 10px;
  position: relative;
  z-index: 1;
}
a {
  cursor: pointer;
  text-decoration: none;
}
.bannerContent img {
  width: 160px;
  height: 100px;
}
.middleTexts {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.middleTexts p {
  margin: 0;
  text-align: left;
  color: white;
}
.middleTexts p:nth-child(1) {
  font-size: 12px;
  font-weight: 400;
}
.middleTexts p:nth-child(2) {
  font-size: 16px;
  font-weight: 700;
  padding: 5px 0px;
}
.middleTexts p:nth-child(3) {
  font-size: 16px;
  font-weight: 700;
}
.bannerContent .button {
  padding: 8px 10px;
  background: transparent;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
}
.startSavingNow {
  display: none;
}
.bannerContainerMobile {
  display: none;
}
.topLeftSavingsMobile {
  display: none;
}
.text-green {
  color: #00ba77;
}

.showMore {
  display: none;
  gap: 10px;
  align-items: center;
}

@media (max-width: 780px) {
  .rounded-card {
    margin: 3px 8px 0;
  }
  .walletCardsLaptop {
    display: none;
  }
  .walletCardsMobile {
    display: block;
  }
  .walletCard {
    padding: 10px;
    height: auto;
  }
  .walletCardsContainerFirst {
    grid-template-columns: 1fr;
  }
  .walletCardsContainer {
    grid-template-columns: 1fr;
  }
  .addFundsWithdraw {
    display: none;
  }
  .addFundsWithdrawMobile {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0px 0px 20px 0px;
  }
  .wallet-amount-card-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .wallet-balance-card-buttons-mobile{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .add-funds,
  .withdraw {
    display: flex;
    justify-content: center;
  }
  .bannerContainer {
    display: none;
  }
  .bannerContainerMobile {
    display: block;
    background: #00ba77;
    height: 250px;
    margin: 20px 0px;
    padding: 0px 20px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
  }
  .coin9 {
    position: absolute;
    top: -25px;
    right: -46px;
    width: 153px;
  }
  .coin10 {
    position: absolute;
    width: 95px;
    right: -42px;
    bottom: 20%;
  }
  .coin7 {
    position: absolute;
    right: 25px;
    bottom: 10px;
    width: 20px;
  }
  .coin8 {
    position: absolute;
    left: 33%;
    bottom: 30%;
    width: 20px;
  }
  .coin11 {
    position: absolute;
    left: 16%;
    bottom: 10px;
    width: 20px;
  }
  .bannerContentMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: 190px;
    padding: 0px;
  }
  .bannerContentMobile img {
    width: 160px;
    height: 100px;
  }
  .startSavingNow {
    display: block;
    padding: 10px;
    background: transparent;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    position: relative;
    z-index: 10;
  }

  .showMore {
    display: flex;
  }

  /* Savings */
  .savingsCalculations {
    grid-template-columns: 1fr;
  }
  .topLeftSavings {
    display: none;
  }
  .rightSavings {
    border-left: none;
    border-top: 1px solid #d8d8d8;
  }
  .topLeftSavings1 {
    flex-direction: column;
  }
  .topLeftSavings1 p {
    width: -webkit-fill-available;
    margin: 0px;
    padding-bottom: 10px;
    text-align: left;
    color: #16161699;
  }
  .amountToSave {
    width: -webkit-fill-available;
  }
  .amountToSave input {
    font-size: 20px;
  }
  .bottomRightSavings p {
    font-size: 20px;
  }
  .topRightSavings span {
    font-size: 20px;
  }
  .topRightSavings1 span {
    font-size: 20px;
  }
  .bottomRightSavings .greenBold {
    font-size: 20px;
  }
  .topLeftSavingsMobile {
    display: flex;
    flex-direction: column;
  }
  .topLeftSavingsMobile p {
    margin: 0px;
    color: #16161699;
  }
  .addFundsSavingsMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .addFundsSavingsMobile a {
    text-decoration: none;
    color: #00ba77 !important;
  }
  .addFundsSavingsMobile img {
    margin: 0px 8px;
  }
  .addFundsSavingsMobile p {
    padding: 10px 0px;
    font-size: 20px;
    font-weight: 700;
    margin: 0px;
    color: #000;
  }
  .calculateContainer {
    justify-content: flex-end;
  }
  .lockupSave {
    margin-top: 10px;
  }
}

@media (min-width: 1442px) {
  .rounded-card-container {
    display: flex;
    justify-content: center;
  }
  .rounded-card {
    max-width: 1200px;
  }
}
