/* .myAccountCard{
    display: flex;
    flex-direction: column;
    padding: 40px 0px;
} */
.accountCard {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  border-bottom: 0.5px solid #d3d3d3;
  column-gap: 50px;
  padding: 40px 300px 100px;
  height: calc(100dvh - 60px - 40px);
  overflow-y: scroll;
}
.accountCardHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  width: 600px;
}
.circleDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}
.circle, .circleImg {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  background-color: #d9d9d9;
  border-radius: 100%;
}
.circleText label {
  font-weight: 400;
  font-size: 16px;
  margin-top: 3px;
  color: #00ba77;
  cursor: pointer;
}

.firstName input {
  width: 100%;
  height: 50px;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 0 10px;
}
.firstName p {
  font-weight: 400;
  font-size: 14px;
}
.lastName input {
  width: 100%;
  height: 50px;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 0 10px;
}
.lastName p {
  font-weight: 400;
  font-size: 14px;
}
.accountCardMid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  margin-top: 30px;
}
.emailDiv input {
  width: 100%;
  height: 50px;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 0 10px;
}
.emailDiv p {
  font-weight: 400;
  font-size: 14px;
}
.phoneNo input {
  width: 100%;
  height: 50px;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 0 10px;
}
.phoneNo p {
  font-weight: 400;
  font-size: 14px;
}
.accountCardBottom {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
}
.address input {
  width: 100%;
  height: 93px;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  padding: 0 10px;
}
.address p {
  font-weight: 400;
  font-size: 14px;
}
.bankInformationCard {
  display: flex;
  flex-direction: column;
  padding: 40px 100px;
}
.bankInformationHeading h3 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  text-align:center;
}
.bankInformationMid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap:10px;

}

.bank-name p {
  font-size: 14px;
  font-weight: 400;
}
.selectDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 51.33px;
  border: 0.5px solid #80808080;
  border-radius: 5px;
}
.selectDiv select {
  border: none;
  outline: none;
  border-radius: 5px;
  margin: 0px 10px;
  width: 100%;
}

.account-no p {
  font-size: 14px;
  font-weight: 400;
}
.account-no input {
  height: 50px;
  border: 0.5px solid #80808080;
  border-radius: 5px;
  width: -webkit-fill-available;
  padding: 0px 10px;
}
.buttonSave {
  padding-top: 25px;
}
.buttonSave p {
  color: white;
  background: #02915d;
  width: max-content;
  padding: 15px 50px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.textarea {
  border: 0.5px solid #80808080;
  border-radius: 5px;
  width: 100%;
  padding: 5px 10px;
}
@media (max-width: 850px) and (min-width: 430px) {
  .accountCardHeader {
    width: 450px;
  }
  .bankInformationCard {
    padding: 40px 40px 40px;
  }
}
/* @media (max-width: 380px) {
  .accountCard {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 40px;
  }
  .accountCardHeader {
    display: grid;
    grid-template-columns: 1fr;
    width: 255px;
  }
  .selectDiv select {
    padding-right: 172px;
  }
  .accountCardMid {
    display: grid;
    grid-template-columns: 1fr;
    width: 255px;
    margin-top: 10px;
  }
  .accountCardBottom {
    width: 255px;
  }
  .bankInformationCard {
    padding: 30px 15px 20px;
  }
  .bankInformationMid {
    display: grid;
    grid-template-columns: 1fr;
    width: 260px;
  }
  .buttonSave {
    padding: 20px 0;
    display: flex;
    justify-content: center;
  }
  .account-no {
    padding-right: 6px;
    width: 255px;
  }
} */
@media (max-width: 710px) {
  .accountCard {
    display: flex;
    padding: 15px 15px 40px;
  }
  .accountCardHeader {
    display: grid;
    grid-template-columns: 1fr;
        width: 100%;
        gap: 10px;
  }
  .selectDiv select {
    padding-right: 20px;
    width: 100%;
  }
  .accountCardMid {
    display: grid;
    grid-template-columns: 1fr;
        width: 100%;
    margin-top: 10px;
    gap: 10px;
  }
  .accountCardBottom {
        width: 100%;
        margin-top: 10px;

  }
  .bankInformationCard {
    padding: 30px 20px 20px;
  }
  .bankInformationMid {
    display: grid;
    grid-template-columns: 1fr;
    /* width: 99%; */
  }
  .buttonSave {
    padding: 20px 0;
    display: flex;
    justify-content: center;
  }
}
