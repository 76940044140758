input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.signin_container {
  background-color: black;
  height: 100vh;
  display: flex;
}

.picture_container {
  width: 44%;
  background-color: #00ba77;
  padding-left: 84px;
}

.error{
  margin: 0;
  color: red;
}

.form_container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56%;
}

.signin_heading {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 0;
}

.signin_para {
  font-size: 14px;
}

.signin_form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
}

.signin_name_inputs {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}

.signin_input_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.signin_input {
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
  padding: 16px;
  color: white;
}

.signin_email_input_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 65%;
}

.signin_password_main_input_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 65%;
  margin-top: 1rem;
}

.signin_password_input {
  background-color: black;
  border-radius: 5px;
  padding: 16px;
  color: white;
  border: none;
  outline: none;
  width: 90%;
}

.signin_password_input_div {
  display: flex;
  align-items: center;
  border: 1px solid white;
  border-radius: 5px;
  padding-right: 16px;
  justify-content: space-between;
}

.signin_input_referral_div {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 65%;
}

.signin_button {
  background-color: #00ba77;
  border: 1px solid #00ba77;
  color: white;
  border-radius: 5px;
  margin-top: 1rem;
  width: 65%;
  padding: 1rem 0;
}

.check_box_div {
  width: 65%;
  display: flex;
  justify-content: center;
}

.check_box {
  margin-top: 20px;
}

.check_box_text {
  font-size: 12px;
}

.signin_otp_container {
  display: flex;
  gap: 20px;
  margin-top: 2rem;
}

.signin_otp_input {
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 55px;
  height: 60px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.signin_otp_input:focus {
  outline: 1px solid #00ba77;
  border: 1px solid #00ba77;
}

.signin_otp_button {
  background-color: #00ba77;
  border: 1px solid #00ba77;
  color: white;
  border-radius: 5px;
  margin-top: 4rem;
  width: 65%;
  padding: 1rem 0;
}

@media (max-width: 426px) {
  .picture_container {
    display: none;
  }

  .form_container {
    width: 100%;
  }

  .signin_form_container{
    width: 100%;
    margin-top: 2rem;
  }

  .signin_button{
    width: 80%;
  }

  .signin_password_main_input_div{
    width: 80%;
  }

  .signin_email_input_div{
    width: 80%;
  }

  .signin_otp_container{
    gap: 5px;
  }

  .signin_otp_input{
    width: 35px;
    height: 40px;
  }

  .signin_para {
    text-align: center;
  }

}
