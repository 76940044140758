.changePasswordCard {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
    align-items: center; */
  padding: 0px 0 200px;
}
.changePasswordHeading {
  display: flex;
  border-bottom: 0.5px solid #d3d3d3;
  align-items: center;
  justify-content: center;
}
.changePasswordHeading h3 {
  font-weight: 500;
  font-size: 22px;
}
.changePasswordBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
.currentPassword p {
  font-weight: 400;
  font-size: 16px;
}
.currentPassword input {
  border: 0.5px solid #80808080;
  padding: 20px;
  width: 522px;
  border-radius: 5px;
}
.newPassword p {
  font-weight: 400;
  font-size: 16px;
  padding-top: 15px;
}
.newPassword input {
  border: 0.5px solid #80808080;
  padding: 20px;
  width: 522px;
  border-radius: 5px;
}
.confirmPassword p {
  font-weight: 400;
  font-size: 16px;
  padding-top: 15px;
}
.confirmPassword input {
  border: 0.5px solid #80808080;
  padding: 20px;
  width: 522px;
  border-radius: 5px;
}
.saveChangesButton {
  margin-top: 60px;
  background-color: #02915d;
  padding: 18px 228px;
  border-radius: 5px;
}
.saveChangesButton a {
  color: white;
}
@media (max-width: 426px) {
  .changePasswordCard {
    padding: 0 0 200px;
  }
  .changePasswordHeading {
    font-weight: 500;
    font-size: 16px;
  }
  .changePasswordHeading h3 {
    padding-top: 0;
  }
  .currentPassword input {
    width: 300px;
  }
  .newPassword input {
    width: 300px;
  }
  .confirmPassword input {
    width: 300px;
  }
  .saveChangesButton {
    margin-top: 40px;
    padding: 18px 118px;
  }
  .currentPassword p {
    font-weight: 400;
    font-size: 13px;
  }
  .newPassword p {
    font-weight: 400;
    font-size: 13px;
  }
  .confirmPassword p {
    font-weight: 400;
    font-size: 13px;
  }
}
@media (max-width: 385px) {
.currentPassword input {
  width: 250px;
}
.newPassword input {
  width: 250px;
}
.confirmPassword input {
  width: 250px;
}
.saveChangesButton {
  margin-top: 40px;
  padding: 18px 90px;
}

}
@media (max-width: 335px) {
    .currentPassword input {
      width: 230px;
    }
    .newPassword input {
      width: 230px;
    }
    .confirmPassword input {
      width: 230px;
    }
    .saveChangesButton {
      margin-top: 40px;
      padding: 18px 80px;
    }
    
    }