input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.signup_container {
  background-color: black;
  height: 100vh;
  display: flex;
}

.picture_container {
  width: 44%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  background-color: #00ba77;
  padding-left: 84px;
  padding-top: 3rem;
}

.form_container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 56%;
  padding-top: 3rem;
}

.error {
  margin: 0;
  color: red;
  text-align: center;
}

.signup_heading {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

.signup_para {
  font-size: 14px;
  margin-bottom: 15px;
}

.signup_form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup_name_inputs {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}

.signup_input_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.signup_input {
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
  padding: 16px;
  color: white;
}

.signup_email_input_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 65%;
}

.signup_password_main_input_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 65%;
  margin-top: 1rem;
}

.signup_password_input {
  background-color: black;
  border-radius: 5px;
  padding: 16px;
  color: white;
  border: none;
  outline: none;
  width: 90%;
}

.signup_password_input_div {
  display: flex;
  align-items: center;
  border: 1px solid white;
  border-radius: 5px;
  padding-right: 16px;
  justify-content: space-between;
}

.signup_input_referral_div {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 65%;
}

.signup_button {
  background-color: #00ba77;
  border: 1px solid #00ba77;
  color: white;
  border-radius: 5px;
  margin-top: 1rem;
  width: 65%;
  padding: 1rem 0;
}

.check_box_div {
  width: 65%;
  display: flex;
  align-items: flex-start;
  gap: 10.72px;
  margin-top: 10px;
}

.check_box {
  margin-top: 20px;
}

.check_box_text {
  font-size: 12px;
}

.signup_otp_container {
  display: flex;
  gap: 20px;
  margin-top: 2rem;
}

.signup_otp_input {
  background-color: black;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  width: 55px;
  height: 60px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.signup_otp_input:focus {
  outline: 1px solid #00ba77;
  border: 1px solid #00ba77;
}

.signup_otp_button {
  background-color: #00ba77;
  border: 1px solid #00ba77;
  color: white;
  border-radius: 5px;
  margin-top: 4rem;
  width: 65%;
  padding: 1rem 0;
}

@media (max-width: 426px) {
  .picture_container {
    display: none;
  }

  .form_container {
    width: 100%;
    padding-top: 10px;
  }

  .signup_form_container {
    width: 100%;
    margin-top: 1rem;
  }

  .signup_name_inputs {
    flex-direction: column;
    gap: 1rem;
    width: 80%;
  }

  .signup_button {
    width: 80%;
  }

  .error {
    font-size: 12px;
    text-align: center;
  }

  .signup_input_referral_div {
    width: 80%;
  }

  .signup_password_main_input_div {
    width: 80%;
  }

  .signup_email_input_div {
    width: 80%;
  }

  .signup_otp_container {
    gap: 5px;
  }

  .signup_otp_input {
    width: 35px;
    height: 40px;
  }

  .signup_input {
    padding: 10px;
  }

  .signup_password_input {
    padding: 10px;
  }

  .signup_para {
    text-align: center;
  }
}
