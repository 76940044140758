.loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c9c9c9; /* Light grey background color */
    position: relative;
    overflow: hidden;
}

.loading-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #c9c9c9, #f4f4f4); /* Gradient from light grey to dark grey */
    animation: moveBackAndForth 2s linear infinite; /* Adjust duration and timing function as needed */
}

@keyframes moveBackAndForth {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}