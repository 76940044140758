.card-header {
  display: flex;
  align-items: center;
  padding: 10px 100px;
  border-bottom: 1px solid #ccc;
}
.accountSettingDiv {
  padding: 15px 0 200px;
}
.accountSettingHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cccccc80;
  padding-bottom: 6px;
}
.accountSettingHeading h3 {
  font-weight: 500;
  font-size: 16px;
}
.accountSettingCards a {
  text-decoration: none;
  color: black;
}
.accountSettingCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 17px;
  border-bottom: 1px solid #cccccc80;
  cursor: pointer;
}
.accountSettingCard p {
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 1440px) and (min-width: 426px) {
  .accountSettingDiv {
    display: none;
  }
  .for-mobile {
    display: none;
  }
}
@media (max-width: 425px) {
        .for-laptop {
                display: none;
        }

        .accountSettingDiv {
                height: calc(100dvh - 60px - 40px);
                padding: 15px 0 60px;
        }
}

@media (min-width: 1440px) {
  .rounded-card-container {
    display: flex;
    justify-content: center;
  }
  .rounded-card {
    max-width: 1200px;
  }
  .for-mobile {
    display: none;
  }
  .accountSettingDiv {
    display: none;
  }
}
