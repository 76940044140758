.marketContainer {
  display: flex;
  flex-direction: row;

  width: 100%;
  /* background: red; */
}

.expandedTechnicalContainer {
  background: white;
  padding: 0px 20px;
}

.technicalExtras {
  background: white;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  box-shadow: 0px 0px 5px 1px rgba(0.1, 0.1, 0.1, 0.1);
}

.leftTechnicals {
  width: calc(25% - 2px);
  overflow-y: auto;
  margin: 2px;
  background: white;
}

.middleTechnicals {
  background: white;
}

.width75 {
  width: 75%;
}
.width45 {
  width: 45%;
}

.technicalExtrasContainer {
  padding: 10px 20px;
}

.rightTechnicals {
  width: calc(30% - 2px);
  overflow-y: auto;
  margin: 2px;
}

.marketsNoTextEquity {
  display: flex;
  justify-content: center;
}
.stockDetail {
  display: flex;
  justify-content: space-between;
}

.stockDetail img {
  width: 50px;
  height: 50px;
}

.nameAndDesc {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 5px;
}

.nameAndDesc .name {
  font-size: 22px;
  font-weight: 500;
}

.nameAndDesc .description {
  font-size: 14px;
}

.nameAndDesc p {
  margin: 0px;
}

.candleStickContainer {
  display: flex;
  align-items: center;
  height: 53vh;
  
}

.candleStickContainerExpanded {
  display: flex;
  align-items: center;

  padding: 40px 0px;
}

.orderBookContainer {
  margin: 2px;
  background: white;
  border-radius: 5px;
  height: 190px;
}

.orderBook {
  margin: 2px;
  background: white;
  border-radius: 5px;
}

.orderBookHeader {
  border-bottom: 1px solid #ccc;
}

.orderBookHeader p {
  margin: 0px;
  font-weight: 400;
  padding: 10px 5px;
}

.orderBookChart {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* overflow: hidden; */
}

.orderBookChart canvas {
  width: 100% !important;
  height: 130px !important;
}

.asks {
  position: relative;
}
.bids {
  position: relative;
}
.tradeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 5px 20px;
}

.buyButton {
  background: #00ba77;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sellButton {
  background: #ff5348;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyButton p {
  margin: 8px;
}

.sellButton p {
  margin: 8px;
}

.nonActiveButton {
  background: #f5f5f5;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nonActiveButton p {
  color: #515151;
  margin: 8px;
}

.orderType p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
}
.orderTypeSelect {
  padding: 8px;
  background-color: transparent;
  border-radius: 5px;
  border: 0.3px solid #808080;
}

.orderTypeSelect select {
  background-color: transparent;
  font-size: 13px;
  width: -webkit-fill-available;
  border: none;
  outline: none;
}

.orderTypeSelect input {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 13px;
  width: 100%;
}

.timeInForce {
  padding: 0px 20px;
}

.timeInForce p {
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0px;
}

.mainButton {
  padding: 10px 20px;
}

.bidAskHeadings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30;
}

.bidSize {
  font-size: 12px;
  font-weight: 400;
  margin: 5px;
}

.askSize {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  margin: 5px;
}

.askBG {
  background-color: #ff53481a;
  border: 0.5px solid white;
  padding-left: 10px;
}

.bidBG {
  background-color: #00ba771a;
  border: 0.5px solid white;
  padding-right: 10px;
}

.bidText {
  font-size: 12px;
  color: #00ba77;
  text-align: right;
  margin: 5px;
}
.askText {
  font-size: 12px;
  color: #ff5348;
  text-align: left;
  margin: 5px;
}

.bidAskContainer {
  margin: 2px;
  background: white;
  border-radius: 5px;
  padding: 10px;
}

.bidAskItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 426px) {
  .technicalsLaptop {
    display: none;
  }

  .mainAreaContainerMobile {
    /* display: flex;
    align-items: center; */
    height: 500px;
    /* width: 500px; */
    padding: 0px 10px;
    margin-top: 20px;
  }
}
