.alertMobileLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 60px);
}
.manualHistory {
  height: 100%;
}
.historyMobileCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  border-radius: 5px;
  margin: 2px;
  padding: 5px 20px;
  align-items: center;
  position: relative;
}
.historyMobileCard p {
  font-weight: 500;
  font-size: 22px;
  color: #5e5e5e;
}
.newChatButton p {
  font-weight: 500;
  font-size: 14px;
  color: white;
  background: #00ba77;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.alertLeftTop {
  /* overflow-y: auto;
    height: 70%; */
}

.alertLeftTop.scrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertLeftTop.scrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

.alertLeftTop.scrolling::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftTop.scrolling::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftTop.notscrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertLeftTop.notscrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
  background-color: transparent; /* Change the color as needed */
}

.alertLeftTop.notscrolling::-webkit-scrollbar-thumb {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftTop.notscrolling::-webkit-scrollbar-track {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.time-remaining {
  background-color: white;
  border-radius: 5px;
  margin: 2px 2px;
}
.time-remaining .date {
  font-size: 16px;
  font-weight: 500;
  color: #cccccccc;
  margin: 5px 0px;
}
.time-remaining {
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
}

.time-remaining .item {
  padding: 5px 0px;
  cursor: pointer;
}
.alertLeftBottom {
  background-color: white;
  border-radius: 5px;
  overflow-y: auto;
  height: 84%;
  padding: 10px;
  margin: 5px 2px;
}
.alertLeftBottom.scrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertLeftBottom.scrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
}

.alertLeftBottom.scrolling::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftBottom.scrolling::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftBottom.notscrolling {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: transparent transparent; /* Firefox */
}

.alertLeftBottom.notscrolling::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
  background-color: transparent; /* Change the color as needed */
}

.alertLeftBottom.notscrolling::-webkit-scrollbar-thumb {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}

.alertLeftBottom.notscrolling::-webkit-scrollbar-track {
  background-color: transparent; /* Change the color as needed */
  /* border-radius: 4px; */
}
.alertLeftBottom h4 {
  font-weight: 500;
  font-size: 16px;
  color: #515151;
  margin: 10px 0px;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th {
  background-color: #f5f5f5;
  color: black;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
}

.custom-table td,
.custom-table th {
  border: 0.5px solid #f0f0f0;
  padding: 8px;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
}

.custom-table img {
  padding: 5px;
}
